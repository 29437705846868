import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

type GithubIconPropsType = {
  id?: string
} & SvgIconProps
const GithubIcon: React.FC<GithubIconPropsType> = ({id, fill, ...props}) => {
  return (
    <SvgIcon width='20' height='20' viewBox="0 0 20 20" fill='none' {...props}>
      <g clipPath={`url(#clip0_71_114${id || ''})`}>
      <path fill={fill ?? "white"} d="M10 0C4.47833 0 0 4.4775 0 10C0 14.4183 2.865 18.1667 6.83917 19.4892C7.33833 19.5817 7.5 19.2717 7.5 19.0083V17.1467C4.71833 17.7517 4.13917 15.9667 4.13917 15.9667C3.68417 14.8108 3.02833 14.5033 3.02833 14.5033C2.12083 13.8825 3.0975 13.8958 3.0975 13.8958C4.10167 13.9658 4.63 14.9267 4.63 14.9267C5.52167 16.455 6.96917 16.0133 7.54 15.7575C7.62917 15.1117 7.88833 14.67 8.175 14.4208C5.95417 14.1667 3.61917 13.3092 3.61917 9.47833C3.61917 8.38583 4.01 7.49417 4.64917 6.79417C4.54583 6.54167 4.20333 5.52417 4.74667 4.1475C4.74667 4.1475 5.58667 3.87917 7.4975 5.1725C8.295 4.95083 9.15 4.84 10 4.83583C10.85 4.84 11.7058 4.95083 12.505 5.1725C14.4142 3.87917 15.2525 4.1475 15.2525 4.1475C15.7967 5.525 15.4542 6.5425 15.3508 6.79417C15.9925 7.49417 16.38 8.38667 16.38 9.47833C16.38 13.3192 14.0408 14.165 11.8142 14.4125C12.1725 14.7225 12.5 15.3308 12.5 16.2642V19.0083C12.5 19.2742 12.66 19.5867 13.1675 19.4883C17.1383 18.1642 20 14.4167 20 10C20 4.4775 15.5225 0 10 0Z" />
      </g>
      <defs>
      <clipPath id={`clip0_71_114${id || ''}`}>
      <rect fill={fill ?? "white"} width="20" height="20" />
      </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default GithubIcon